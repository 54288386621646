<template>
<div class="window window-metriks window-metriks_main window_full">
    <div class="window-header">
        <img src="@/assets/img/icons/icon-metriks.svg" alt="Иконка" class="window-header__icon" />
        <div class="window-header__title">Мои главные метрики</div>
        <div
            v-if="metriks && metriks.length >= 6"
            class="window-header__more"
            @click="showMore()"
        >
            Показать все
        </div>
    </div>
    <div class="window-body">
        <div
            class="window-item_metriks"
            v-for="item in data"
            :key="item.id"
            
        >
            <div v-if="item.type == 'revenue'" class="window-item-icon">
                <img src="@/assets/img/icons/metriks/icon-metrik-1.svg" alt="Иконка" />
            </div>
            <div v-if="item.type == 'orders'" class="window-item-icon">
                <img src="@/assets/img/icons/metriks/icon-metrik-2.svg" alt="Иконка" />
            </div>
            <div v-if="item.type == 'average_check'" class="window-item-icon">
                <img src="@/assets/img/icons/metriks/icon-metrik-3.svg" alt="Иконка" />
            </div>
            <div v-if="item.type == 'hours'" class="window-item-icon">
                <img src="@/assets/img/icons/metriks/icon-metrik-3.svg" alt="Иконка" />
            </div>
            <div v-if="item.type == 'nps'" class="window-item-icon">
                <img src="@/assets/img/icons/metriks/icon-metrik-5.svg" alt="Иконка" />
            </div>
            <div v-if="item.type == 'reviews'" class="window-item-icon">
                <img src="@/assets/img/icons/metriks/icon-metrik-4.svg" alt="Иконка" />
            </div>
            <div class="window-item-data">
                <div
                    v-if="item.code === 'tubatay__reviews'"
                    class="window-item-data__title"
                >
                    {{ item.description.substring(0, 60) }}...
                </div>
                <div class="window-item-data__title">{{ item.value }}</div>
                <div class="window-item-data__subtitle">{{ item.name }}</div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    data() {
        return {
            data: null,
            more: false,
        }
    },

    props: {
        metriks: {
            type: Object,
            default: null
        }
    },

    methods: {
        getMetriks(limit) {
            if(limit && limit <= this.metriks.length) {
                const array = this.metriks
                this.data = array.slice(0, limit)
            } else {
                this.data = this.metriks
            }
        },

        showMore() {
            if(this.more) {
                this.more = !this.more
                this.getMetriks(6)
            } else {
                this.more = !this.more
                this.getMetriks()
            }
        }
    },

    watch: {
        metriks(newValue) {
            if(newValue) {
                this.getMetriks(6)
            }
        }
    },

    mounted() {
        if(this.metriks) {
            this.getMetriks(6)
        }
    }
}
</script>
<style scoped lang="sass">
.window-header__more
    margin-left: auto
    color: var(--color-blue)
    cursor: pointer
    &:hover
        text-decoration: underline
</style>