<template>
    <nav class="nav">
        <div class="nav-left">
            <a href="/" class="nav__logo">
                <img src="@/assets/img/logo.svg" alt="">
            </a>
            <h1
                class="nav__name"
                v-if="
                    $route.name !== 'login' && 
                    first_name !== '' && 
                    $route.name !== 'invoice'
                "
            >
                Добрый день, {{ first_name }}!
            </h1>
            <h1
                class="nav__name"
                v-else-if="
                    $route.name !== 'login' && 
                    first_name !== '' && 
                    $route.name !== 'invoice'
                "
            >
                Добрый день, {{ username }}!
            </h1>
        </div>
        <div class="nav-right">
            <!-- <input type="checkbox" class="input-lang-switch" id="switch" />
            <label class="lang-switch" for="switch">
                <span class="lang-switch__span_1">RU</span>
                <span class="lang-switch__span_2">EN</span>
            </label>
            <CustomSelect
                v-if="$route.name !== 'login'"
                v-model="selectShops"
                :options="optionShops"
            /> -->
            <button class="nav__button"  v-if="$route.name !== 'login'">
                <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.4423 8.50028V11.1835C11.4423 11.3534 11.4856 11.5205 11.5682 11.6691L12.2516 12.8992C12.6219 13.5658 12.1399 14.3849 11.3774 14.3849H1.69951C0.937029 14.3849 0.455062 13.5658 0.825357 12.8992L1.50877 11.6691C1.5913 11.5205 1.63462 11.3534 1.63462 11.1835V8.50028V6.61566C1.63462 4.40652 3.42548 2.61566 5.63462 2.61566H6.10256" stroke="#2E343B" stroke-width="0.8" stroke-linecap="round"/>
                    <path d="M3.92334 14.3849H9.15411V15.0003C9.15411 16.1049 8.25868 17.0003 7.15411 17.0003H5.92334C4.81877 17.0003 3.92334 16.1049 3.92334 15.0003V14.3849Z" stroke="#2E343B" stroke-width="0.8"/>
                    <ellipse cx="11.1154" cy="3.26923" rx="3.26923" ry="3.26923" fill="#F2B20E"/>
                </svg>
            </button>
            <button class="nav__button" v-if="$route.name !== 'login'" @click="userLogout()">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.8623 15H2.61928C1.72511 15 1.00024 14.2751 1.00024 13.381V2.61905C1.00024 1.72487 1.72511 1 2.61928 1H4.8623" stroke="black" stroke-linecap="round"/>
                    <path d="M4.8623 7.5C4.58616 7.5 4.3623 7.72386 4.3623 8C4.3623 8.27614 4.58616 8.5 4.8623 8.5V7.5ZM15.3538 8.35355C15.5491 8.15829 15.5491 7.84171 15.3538 7.64645L12.1718 4.46447C11.9765 4.2692 11.66 4.2692 11.4647 4.46447C11.2694 4.65973 11.2694 4.97631 11.4647 5.17157L14.2931 8L11.4647 10.8284C11.2694 11.0237 11.2694 11.3403 11.4647 11.5355C11.66 11.7308 11.9765 11.7308 12.1718 11.5355L15.3538 8.35355ZM4.8623 8.5H15.0002V7.5H4.8623V8.5Z" fill="black"/>
                </svg>
            </button>
        </div>
        <div class="nav-mobile">
            <button class="nav__button" v-if="$route.name !== 'login'" @click="mobileSidebarController()">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.20001 1.20001H14.8" stroke="black" stroke-linecap="round"/>
                    <path d="M1.20007 8.00006H14.8001" stroke="black" stroke-linecap="round"/>
                    <path d="M1.20007 14.8H14.8001" stroke="black" stroke-linecap="round"/>
                </svg>
            </button>
            <!-- <CustomSelect
                v-if="$route.name !== 'login'"
                v-model="selectShops"
                :options="optionShops"
            /> -->
            <button class="nav__button" v-if="$route.name !== 'login'">
                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.25 6.76469V9.36723C12.25 9.55025 12.3002 9.72976 12.3952 9.88621L13.0778 11.0104C13.4824 11.6768 13.0026 12.5294 12.223 12.5294H1.77703C0.997392 12.5294 0.517625 11.6768 0.92224 11.0104L1.60479 9.88621C1.69977 9.72976 1.75 9.55025 1.75 9.36723V6.76469V5C1.75 2.79086 3.54086 1 5.75 1H7.5" stroke="black" stroke-linecap="round"/>
                    <path d="M4.66666 12.5293H9.33332V12.9999C9.33332 14.1044 8.43789 14.9999 7.33332 14.9999H6.66666C5.56209 14.9999 4.66666 14.1044 4.66666 12.9999V12.5293Z" stroke="black"/>
                    <circle cx="11" cy="3" r="2" fill="#E03333"/>
                </svg>
            </button>
            <a href="#" class="nav__logo" v-if="$route.name === 'login'">
                <img src="@/assets/img/logo.svg" alt="">
            </a>
            <div class="nav-mobile-switch" v-if="$route.name === 'login'">
                <input type="checkbox" class="input-lang-switch" id="switch_mobile" />
                <label class="lang-switch" for="switch_mobile">
                    <span class="lang-switch__span_1">RU</span>
                    <span class="lang-switch__span_2">EN</span>
                </label>
            </div>
        </div>
    </nav>
</template>
<script>
import CustomSelect from '@/components/CustomSelect.vue'
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            selectShops: 1,
            optionShops: [
                { label: 'Тюбетей', value: 1 },
                { label: 'Татар', value: 2 },
            ]
        }
    },

    computed: {
        ...mapState ({
            token: state => state.token,
            username: state => state.username,
			first_name: state => state.first_name,
        })
    },

    components: {
        CustomSelect
    },

    methods: {
        ...mapActions ({
            mobileSidebarController: 'mobileSidebarController',
        }),

        userLogout() {
            this.token = null
            localStorage.removeItem('token')
            this.$router.replace('/login')
        },
    },
}
</script>