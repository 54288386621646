import axios from 'axios'
import router from '@/router';

export const reportEditPlanModule = {
    namespaced: true,

    state:() => ({
        reportData: {},
        reportLoading: true,
        excelData: {},
    }),

    mutations: {
        setReportData(state, data) {
            state.reportData = data
        },

        setExcelData(state, data) {
            state.excelData = data
        }
    },

    actions: {
        async getReportData({ commit, state }) {
            state.reportLoading = true
            try {
                const response = await axios.get("https://admin.tcode.online/api/sale-plans/" + router.currentRoute.value.query['shop'] + '?month=' + router.currentRoute.value.query['month'], {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                console.log(response.data);
                commit('setReportData', response.data)
                commit('setExcelData', response.data)
            } catch(err) { // Если есть ошибка
                alert(err)
            }

            state.reportLoading = false
        },

        async changeRevenue({}, data) {
            try {
                const response = await axios.patch("https://admin.tcode.online/api/sale-plans/" + router.currentRoute.value.query['shop'] + '/' + data.id + '/',
                {
                    revenue: data.value
                },
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },

        async changeOrders({}, data) {
            try {
                const response = await axios.patch("https://admin.tcode.online/api/sale-plans/" + router.currentRoute.value.query['shop'] + '/' + data.id + '/',
                {
                    orders: data.value
                },
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },
    }
}