import axios from 'axios'
import router from '@/router';

export const feedbackAllReviewsModule = {
    namespaced: true,

    state:() => ({
        filterset: null,
        filtersetLoading: true,
        filterSpecs: {
            date_range: [],
            shop: [],
            hashtag: [],
            rating: [],
            reviews: []
        },
        feedbackLoading: true,
        feedbackData: null
    }),

    mutations: {
        setFeedbackData(state, data) {
            state.feedbackData = data
            state.feedbackLoading = false
        },

        setFilterset(state, data) {
            state.filterSpecs['date_range'] = []

            if(Object.keys(router.currentRoute.value.query).length == 0) {
                console.log('!');
                for(let filter in data) {
                    if(filter !== 'title') {
                        for(let i = 0; i < data[filter].choices.length; i++) {
                            if(filter === 'date_range') {
                                state.filterSpecs[filter].push(data[filter].choices[i].value)
                            } else {
                                if(data[filter].choices[i].checked) {
                                    state.filterSpecs[filter].push(data[filter].choices[i].value)
                                }
                            }
                        }
                    }
                }
            } else {
                console.log('!!');
                const queries = router.currentRoute.value.query

                queries.date_from ? state.filterSpecs['date_range'].push(queries.date_from) : state.filterSpecs['date_range'].push('')
                queries.date_to ? state.filterSpecs['date_range'].push(queries.date_to) : state.filterSpecs['date_range'].push('')

                if(queries.shop) {
                    if(Array.isArray(queries.shop)) {
                        state.filterSpecs['shop'] = Number(queries.shop)
                    } else {
                        state.filterSpecs['shop'] = [Number(queries.shop)]
                    }
                }

                if(queries.hashtag) {
                    if(Array.isArray(queries.hashtag)) {
                        state.filterSpecs['hashtag'] = queries.hashtag
                    } else {
                        state.filterSpecs['hashtag'] = [queries.hashtag]
                    }
                }

                if(queries.rating) {
                    if(Array.isArray(queries.rating)) {
                        state.filterSpecs['rating'] = queries.rating
                    } else {
                        state.filterSpecs['rating'] = [queries.rating]
                    }
                }

                if(queries.reviews) {
                    if(Array.isArray(queries.reviews)) {
                        state.filterSpecs['reviews'] = queries.reviews
                    } else {
                        state.filterSpecs['reviews'] = [queries.reviews]
                    }
                }
            }
            
            state.filterset = data
            state.filtersetLoading = false
        },

        setExcelData(state, data) {
            state.excelData = data
        }
    },

    actions: {
        async getReportFilterset({ commit, state }) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/reports/all_reviews/filterset", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                state.filtersetLoading = true
                commit('setFilterset', response.data)
            } catch(err) { // Если есть ошибка
                console.log(err);
            }
        },

        getMainData({ commit, state, dispatch }) {
            state.feedbackLoading = true

            if(router.currentRoute.value.fullPath.split('?')[1]) {
                axios
                .get("https://admin.tcode.online/api/reports/all_reviews/data?" + router.currentRoute.value.fullPath.split('?')[1], {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    console.log(response.data);
                    commit('setFeedbackData', response.data.data)
                    commit('setExcelData', response.data)
                })
                .catch((error) => {
                    console.log(error.response);
                });
            }
        },

        async getExcelFile({ state }) {
            try {
                const response = await axios.post("https://admin.tcode.online/api/reports/export_to_excel/",
                state.excelData,
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    },
                    responseType: 'blob'
                })
                const now = new Date()
                const url = URL.createObjectURL(response.data)
                const a = document.createElement('a')
                a.href = url
                a.download = `${state.excelData.title}_${now.toLocaleDateString()}.xls`
                a.style.display = 'none'
                document.body.appendChild(a)
                a.click()
                a.remove()
                URL.revokeObjectURL(url)
                
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },
    }
}
