import axios from 'axios'
import api from '@/api'

export const TimeManagementModule = {
    namespaced: true,

    state:() => ({
        isLoading: true,
        employees: null,
        employeeData: null
    }),

    mutations: {
        setEmployees(state, data) {
            state.employees = data
        },

        setEmployeeData(state, data) {
            
            const formatMonth = (time) => {
                time = time.split(':')

                return {
                    hours: time[0],
                    minutes: time[1],
                    seconds: time[2]
                };
            }

            for(let i = 0; i < data.shift_opportunities.length; i++) {
                if(data.shift_opportunities[i].start_time) {
                    data.shift_opportunities[i].start_time = formatMonth(data.shift_opportunities[i].start_time)
                }
                if(data.shift_opportunities[i].end_time) {
                    data.shift_opportunities[i].end_time = formatMonth(data.shift_opportunities[i].end_time)
                }
                if(data.shift_opportunities[i].unavailable_start_time) {
                    data.shift_opportunities[i].unavailable_start_time = formatMonth(data.shift_opportunities[i].unavailable_start_time)
                }
                if(data.shift_opportunities[i].unavailable_end_time) {
                    data.shift_opportunities[i].unavailable_end_time = formatMonth(data.shift_opportunities[i].unavailable_end_time)
                }
            }
            
            state.employeeData = data
        }
    },

    actions: {
        getEmployees({ state, commit }, data) {
            state.isLoading = true
            axios
            .get(api + "/" + data + "/shift-employees/",
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setEmployees', response.data)
                state.isLoading = false

                console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
        },

        getEmployeeData({ state, commit }, data) {
            state.isLoading = true

            axios
            .get(api + "/" + data.shop + "/shift-employees/" + data.id,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setEmployeeData', response.data)
                state.isLoading = false
            })
            .catch((error) => {
                console.log(error);
            });
        },

        saveEmployeeChanges({ dispatch }, data) {
            let employee = null

            for(let i = 0; i < data.days.length; i++) {
                if(!employee) {
                    employee = data.days[i].employee
                }

                axios
                .patch(api + "/" + data.shop + "/shift-opportunities/" + data.days[i].id + '/', 
                data.days[i],
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    },
                    
                })
                .then(() => {
                })
                .catch((error) => {
                    alert(error)
                });
            }

            dispatch('getEmployeeData', { shop: data.shop , id: employee })
        },

        sendRemoveEmployee({ dispatch }, data) {
            console.log(data)
            axios
            .patch(api + "/" + data.shop + "/shift-employees/" + data.employee + '/', {
                is_active: false
            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('getEmployees', data.shop)
            })
            .catch((error) => {
                console.log(error);
            });
        },

        sendAddEmployee({ dispatch }, data) {
            axios
            .patch(api + "/" + data.shop + "/shift-employees/" + data.employee + '/?all=true', {
                is_active: true
            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('getEmployees', data.shop)
            })
            .catch((error) => {
                console.log(error);
            });
        },

        changeEmployeePhone({}, data) {
            axios
            .patch(api + "/" + data.shop + "/shift-employees/" + data.employee.id + '/?all=true', {
                phone: data.employee.phone
            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }
}