<template>
    <div v-if="multi" class="custom-select" v-click-outside="handleFocusOut">
        <div class="custom-select-header" :class="active ? 'active' : ''" @click="dropdownController()">
            <slot v-if="icon" name='icon'></slot>
            <input
                class="custom-select-input" type="text"
                :placeholder="placeholder"
                v-model="search"
                :class="itemsLabel.length < 1 ? 'empty' : ''"
                :readonly="!active || !with_search"
                :disabled="disabled"
            >
            <div class="custom-select-header-list" :class="active ? '' : 'active', icon ? 'icon' : ''">
                <span
                    class="custom-select-header-list__item"
                    v-for="item in itemsLabel"
                    :key="item"
                >
                    {{ item }},
                </span>
            </div>
            <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 9L12 15L18 9" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
        </div>
        <div class="custom-select-body" :class="active ? 'active' : ''">
            <span
                v-if="options.length <= 0"
                class="custom-select-item empty"
            >
                Пустой список
            </span>
            <div
                v-else
                class="custom-select-item"
                :class="all ? 'checked' : ''"
                @click="selectAll()"
            >
                <span>Все</span>
                <svg class="check" width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 12.6111L8.92308 17.5L20 6.5" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
            </div>
            <div
                class="custom-select-item"
                v-for="option in options.filter(x => String(x.label).toLowerCase().includes(search.toLowerCase()))"
                :key="option.value"
                :class="option.checked ? 'checked' : ''"
                @click="option.checked ? option.checked = false : option.checked = true; valueController()"
            >
                <span>{{ option.label }}</span>
                <svg class="check" width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 12.6111L8.92308 17.5L20 6.5" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
            </div>
        </div>
    </div>

    <div v-else class="custom-select" v-click-outside="handleFocusOut">
        <div class="custom-select-header" :class="active ? 'active' : '', itemValue" @click="dropdownController()">
            <slot name='icon'></slot>
            <input
                class="custom-select-input" type="text"
                :placeholder="placeholder"
                v-model="search"
                :class="itemLabel == null ? 'empty' : ''"
                :readonly="!active || !with_search"
                :disabled="disabled"
            >
            <div class="custom-select-header-list" :class="active ? '' : 'active', icon ? 'icon' : ''">
                <span
                    class="custom-select-header-list__item"
                >
                    {{ itemLabel }}
                </span>
            </div>
            <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 9L12 15L18 9" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
        </div>
        <div v-if="options" class="custom-select-body" :class="active ? 'active' : ''">
            <div
                class="custom-select-item"
                v-for="option in options.filter(x => String(x.label).toLowerCase().includes(search.toLowerCase()))"
                :key="option.value"
                :class="itemLabel === option.label ? 'selected' : '', option.value"
                @click="valueController(option.value)"
            >
                <slot name="options-icon"></slot>
                <span>{{ option.label }}</span>
                <svg class="check" width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 12.6111L8.92308 17.5L20 6.5" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
            </div>
        </div>
        <div v-else class="custom-select-body" :class="active ? 'active' : ''">
            <div
                class="custom-select-item empty"
            >
                Пустой список
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            id: 0,
            active: false,
            search: '',
            all: false,
            itemsLabel: [],
            itemsValue: [],
            itemLabel: null,
            itemValue: null,
        }
    },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: Boolean,
            default: false
        },
        tabindex: {
            type: Number,
            default: 0
        },
        options: {
            type: Object,
            default: {}
        },
        multi: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'Все'
        },
        modelValue: {
            default: null
        },
        defaultValue: {
            type: Boolean,
            default: true
        },
        with_search: {
            type: Boolean,
            default: true,
        },
    },

    methods: {
        dropdownController() {
            if(!this.disabled) {
                if(this.active) {
                    this.active = false
                } else {
                    this.active = true
                }
            }
        },

        valueController(value) {
            if(this.multi) {
                let labels = []
                let values = []

                for(let i = 0; i < this.options.length; i++) {
                    if(this.options[i].checked) {
                        labels.push(this.options[i].label)
                        values.push(this.options[i].value)
                    }
                }
                
                this.itemsLabel = labels
                this.itemsValue = values

                this.search = ''
                this.$emit('update:modelValue', this.itemsValue)
            } else {
                this.itemValue = this.options.filter(item => item.value === value)
                            .map(item => {
                                return item.value;
                            }).toString()
                this.itemLabel = this.options.filter(item => item.value === value)
                                .map(item => {
                                    return item.label;
                                }).toString()

                this.search = ''
                this.active = false
                this.$emit('update:modelValue', this.itemValue)
            }
            this.$emit('onSelect', this.itemValue)
        },

        handleFocusOut() {
            this.search = ''
            if(this.active) {
                this.active = false
            }
        },

        selectAll() {
            if(this.all) {
                this.all = false
                for(let i = 0; i < this.options.length; i++) {
                    this.options[i].checked = false
                }
                this.valueController()
            } else {
                this.all = true
                for(let i = 0; i < this.options.length; i++) {
                    this.options[i].checked = true
                }
                this.valueController()
            }
        }
    },

    mounted() {
        if(!this.multi && this.options) {
            if(this.defaultValue) {
                if(!this.modelValue) {
                    this.itemLabel = String(this.options[0].label)
                    this.itemValue = this.options[0].value
                    this.$emit('update:modelValue', this.options[0].value)
                } else {
                    for(let i = 0; i < this.options.length; i++) {
                        if(this.modelValue === this.options[i].value) {
                            this.itemLabel = String(this.options[i].label)
                            this.itemValue = this.options[i].value
                            if(typeof this.options[i] === 'object') {
                                this.options[i].checked = true
                            }
                        }
                    }
                }
            }
        } else if(this.multi && this.options) {
            if(this.defaultValue) {
                if(this.modelValue.length === 0) {
                    this.all = true
                    for(let i = 0; i < this.options.length; i++) {
                        if(this.options[i].checked) {
                            this.itemsLabel.push(String(this.options[i].label))
                            this.itemsValue.push(this.options[i].value)
                        }
                    }
                } else {
                    for(let i = 0; i < this.options.length; i++) {
                        this.options[i].checked = false
                    }

                    for(let i = 0; i < this.modelValue.length; i++) {
                        const elem = this.options.find(item => item.value == this.modelValue[i])
                        elem.checked = true

                        this.itemsLabel.push(String(elem.label))
                        this.itemsValue.push(elem.value)
                    }              
                }
            } else {
                for(let i = 0; i < this.modelValue.length; i++) {
                    const elem = this.options.find(item => item.value == this.modelValue[i])
                    elem.checked = true

                    this.itemsLabel.push(String(elem.label))
                    this.itemsValue.push(elem.value)
                }   
            }
        }
    },

    watch: { 
        modelValue() {
            if(!this.modelValue) {
                if(this.multi) {
                    this.itemsValue = []
                    this.itemsLabel = []
                } else {
                    this.itemValue = null
                    this.itemLabel = null
                }
            } else {
                if(this.multi && this.modelValue.length === 0) {
                    this.itemsValue = []
                    this.itemsLabel = []
                    for(let i = 0; i < this.options.length; i++) {
                       this.options[i].checked = false
                    }
                } else {
                    for(let i = 0; i < this.options.length; i++) {
                        if(this.options[i].value === this.modelValue) {
                            this.itemValue = this.modelValue
                            this.itemLabel = String(this.options[i].label)
                        }
                    }
                }
            }
        }
    },
}
</script>