import axios from "axios";
import router from "@/router";

export const documentsRegistryModule = {
    namespaced: true,

    state: () => ({
        documents: {},
        isLoading: true,
        departments: null,
        currentDocument: null,
    }),

    mutations: {
        setDocuments(state, data) {
            state.documents = data;
            state.isLoading = false;
        },

        setDepartments(state, data) {
            for(let i = 0; i < data.length; i++) {
                data[i].value = data[i].id
                data[i].label = data[i].name
                data[i].checked = false
            }

            data.unshift({
                label: 'Все',
                value: 'all',
                checked: true
            })
            state.departments = data
        },

        setCurrentDocument(state, data) {
            state.currentDocument = data
            state.isLoading = false
        }
    },

    actions: {
        async fetchDocuments({ commit, state }, section) {
            state.isLoading = true;
            try {
                const response = await axios.get(`https://admin.tcode.online/api/projects/tcode/reports/?${section}`, {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                });
                console.log(response.data)
                commit("setDocuments", response.data);
            } catch (err) {
                // Если есть ошибка
                alert(err);
            }
        },

        fetchDepartments({ commit }, data) {
            axios
            .get("https://admin.tcode.online/api/projects/tcode/departments/",
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then(response => {
                commit('setDepartments', response.data)
            }).catch(error => {
                console.log(error);
            })
        },

        editDocument({ dispatch }, data) {
            axios
            .patch("https://admin.tcode.online/api/projects/tcode/reports/"+ data.data.id +"/",
            {
                name: data.data.name,
                description: data.data.description,
                is_accepted: data.data.is_accepted
            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then(response => {
                console.log(response.data)
                dispatch('fetchDocuments', data.section)
            }).catch(error => {
                console.log(error);
            })
        },

        deleteDocument({ dispatch },  data) {
            axios
            .delete("https://admin.tcode.online/api/projects/tcode/reports/"+ data.id +"/",
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then(response => {
                dispatch('fetchDocuments', data.section)
            }).catch(error => {
                console.log(error);
            })
        },

        fetchCurrentDocument({ state, commit }, id) {
            state.isLoading = true;
            axios
            .get(`https://admin.tcode.online/api/projects/tcode/reports/${id}`,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then(response => {
                console.log(response.data)
                commit('setCurrentDocument', response.data)
            }).catch(error => {
                console.log(error);
            })
        },

        async getExcelFile({ state }) {
            if(state.currentDocument) {
                const data = state.currentDocument

                try {
                    const response = await axios.post(
                        "https://admin.tcode.online/api/reports/export_to_excel/",
                        data.data,
                        {
                            headers: {
                                Authorization: "Token " + localStorage.getItem("token"),
                            },
                            responseType: "blob",
                        }
                    );
                    const now = new Date();
                    const url = URL.createObjectURL(response.data);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `${data.name}_${now.toLocaleDateString()}.xls`;
                    a.style.display = "none";
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    URL.revokeObjectURL(url);
                } catch (err) {
                    // Если есть ошибка
                    alert(err);
                }
            }
            
        },
    },
};
