import axios from 'axios'
import router from '@/router';

export const reportFoodCostModule = {
    namespaced: true,

    state:() => ({
        reportData: {},
        reportLoading: true,
        filterset: {},
        filtersetLoading: true,
        filterSpecs: {},
        excelData: {},
    }),

    mutations: {
        setReportData(state, data) {
            state.reportData = data
        },

        setReportFilterset(state, data) {
            state.filterSpecs['date_range'] = []

            if(Object.keys(router.currentRoute.value.query).length == 0) {
                for(let filter in data) {
                    if(filter !== 'title' && filter !== 'post') {
                        for(let i = 0; i < data[filter].choices.length; i++) {
                            state.filterSpecs[filter] = data[filter].choices[i].value
                        }
                    }
                }
            } else {
                const queries = router.currentRoute.value.query

                queries.date_to ? state.filterSpecs['date_range'] = queries.date_to : state.filterSpecs['date_range'] = new Date()

                queries.date ?
                    state.filterSpecs['month_range'] = {
                        month: Number(queries.date.split("-")[1] - 1),
                        year: Number(queries.date.split("-")[0])
                    }
                    : state.filterSpecs['month_range'] = new Date()
            }
            
            state.filterset = data
            state.filtersetLoading = false
        },

        setExcelData(state, data) {
            state.excelData = data
        },

        setPrevData(state, data) {
            state.prevData = data
        }
    },

    actions: {
        async getReportData({ commit, state }) {
            state.reportLoading = true
            // Проверяет, есть ли параметры которые можно передать в запросе
            if(router.currentRoute.value.fullPath.split('?')[1]) {
                try {
                    const response = await axios.get("https://admin.tcode.online/api/reports/food_cost/data?" + router.currentRoute.value.fullPath.split('?')[1], {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    commit('setReportData', response.data.data)
                    commit('setExcelData', response.data)
                } catch(err) { // Если есть ошибка
                    alert(err)
                }
            // Если нет, делать запрос без параметров
            } else {
                try {
                    const response = await axios.get("https://admin.tcode.online/api/reports/food_cost/data", {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    console.log(response.data);
                    commit('setReportData', response.data.data)
                    commit('setExcelData', response.data)
                } catch(err) { // Если есть ошибка
                    alert(err)
                }
            }

            state.reportLoading = false
        },

        async getReportFilterset({ commit }) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/reports/food_cost/filterset", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                console.log(response.data);
                commit('setReportFilterset', response.data)
            } catch(err) { // Если есть ошибка
                console.log(err);
            }
        },

        resetFilters({ state }) {
            for(let spec in state.filterSpecs) {
                if(Array.isArray(state.filterSpecs[spec])) {
                    state.filterSpecs[spec] = []
                } else {
                    state.filterSpecs[spec] = ''
                }
            }
        },

        async getExcelFile({ state }) {
            console.log(state.excelData);
            try {
                const response = await axios.post("https://admin.tcode.online/api/reports/export_to_excel/",
                state.excelData,
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    },
                    responseType: 'blob'
                })

                const url = URL.createObjectURL(response.data)
                const a = document.createElement('a')
                a.href = url
                a.download = 'report_foodcost.xls'
                a.style.display = 'none'
                document.body.appendChild(a)
                a.click()
                a.remove()
                URL.revokeObjectURL(url)
                
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },

        async changeValue({ dispatch }, data) {
            console.log(data);
            try {
                const response = await axios.patch("https://admin.tcode.online/api/reports/food_cost/edit/plan/" + data.id + '/', {
                    percent: data.value
                },
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                dispatch('getReportData')
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },
    }
}