import axios from "axios";
import router from "@/router";

export const reportTableUpdatesModule = {
    namespaced: true,

    state: () => ({
        reportData: {},
        reportLoading: true,
        filterset: {},
        filtersetLoading: true,
        filterSpecs: {},
        excelData: {},
    }),

    mutations: {
        setReportData(state, data) {
            console.log(data);
            state.reportData = data;
            state.reportLoading = false;
        },

        setReportFilterset(state, data) {
            state.filterSpecs['date_range'] = []
            state.filterSpecs['time_range'] = []

            if(Object.keys(router.currentRoute.value.query).length == 0) {
                for(let filter in data) {
                    if(filter !== 'title') {
                        for(let i = 0; i < data[filter].choices.length; i++) {
                            if(filter === 'date_range') {
                                state.filterSpecs[filter].push(data[filter].choices[i].value)
                            } else if(filter === 'time_range') {
                                state.filterSpecs[filter] = [
                                    {
                                        hours: data[filter].choices[0].value.split(':')[0],
                                        minutes: data[filter].choices[0].value.split(':')[1]
                                    },
                                    {
                                        hours: data[filter].choices[1].value.split(':')[0],
                                        minutes: data[filter].choices[1].value.split(':')[1]
                                    },
                                ]
                            } else {
                                if(data[filter].choices[i].checked) {
                                    state.filterSpecs[filter] = data[filter].choices[i].value
                                }
                            }
                        }
                    }
                }
            } else {
                const queries = router.currentRoute.value.query

                state.filterSpecs['date_range'] = []
                state.filterSpecs['time_range'] = []

                queries.date_from ? state.filterSpecs['date_range'].push(queries.date_from) : state.filterSpecs['date_range'].push('')
                queries.date_to ? state.filterSpecs['date_range'].push(queries.date_to) : state.filterSpecs['date_range'].push('')

                queries.shop ? state.filterSpecs['shop'] = Number(queries.shop) : state.filterSpecs['shop'] = ''

                if(queries.time_from || queries.time_to) {
                    state.filterSpecs['time_range'] = [
                        {
                            hours: '00',
                            minutes: '00'
                        },
                        {
                            hours: '23',
                            minutes: '00'
                        }
                    ]

                    if(queries.time_from) {
                        state.filterSpecs['time_range'][0] = {
                            hours: queries.time_from.split(':')[0],
                            minutes: queries.time_from.split(':')[1]
                        }
                    }
                    if(queries.time_to) {
                        state.filterSpecs['time_range'][1] = {
                            hours: queries.time_to.split(':')[0],
                            minutes: queries.time_to.split(':')[1]
                        }
                    }
                }
            }
            
            state.filterset = data
            state.filtersetLoading = false
        },

        setExcelData(state, data) {
            state.excelData = data;
        },
    },

    actions: {
        async getReportData({ commit, state }) {
            state.reportLoading = true;
            // Проверяет, есть ли параметры которые можно передать в запросе
            if (router.currentRoute.value.fullPath.split("?")[1]) {
                try {
                    const response = await axios.get(
                        "https://admin.tcode.online/api/reports/table_updates/data?" +
                            router.currentRoute.value.fullPath.split("?")[1],
                        {
                            headers: {
                                Authorization: "Token " + localStorage.getItem("token"),
                            },
                        }
                    );
                    commit("setReportData", response.data.data);
                    commit("setExcelData", response.data);
                } catch (err) {
                    // Если есть ошибка
                    alert(err);
                }
                // Если нет, делать запрос без параметров
            } else {
                try {
                    const response = await axios.get("https://admin.tcode.online/api/reports/table_updates/data", {
                        headers: {
                            Authorization: "Token " + localStorage.getItem("token"),
                        },
                    });
                    commit("setReportData", response.data.data);
                    commit("setExcelData", response.data);
                } catch (err) {
                    // Если есть ошибка
                    alert(err);
                }
            }
        },

        async getReportFilterset({ commit }) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/reports/table_updates/filterset", {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                });
                commit("setReportFilterset", response.data);
            } catch (err) {
                // Если есть ошибка
                console.log(err)
            }
        },

        async getExcelFile({ state }) {
            console.log(state.excelData)
            try {
                const response = await axios.post(
                    "https://admin.tcode.online/api/reports/export_to_excel/",
                    state.excelData,
                    {
                        headers: {
                            Authorization: "Token " + localStorage.getItem("token"),
                        },
                        responseType: "blob",
                    }
                );
                const now = new Date();
                const url = URL.createObjectURL(response.data);
                const a = document.createElement("a");
                a.href = url;
                a.download = `${state.excelData.title}_${now.toLocaleDateString()}.xls`;
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(url);
            } catch (err) {
                // Если есть ошибка
                alert(err);
            }
        },
    },
};
