import axios from 'axios'
import api from '@/api'
import router from '@/router'

export const gameModule = {
    namespaced: true,

    state:() => ({
        questions: null,
        currentQuestionID: null,
        currentQuestion: null,
    }),

    mutations: {
        setQuestions(state, data) {
            for(let i = 0; i < data.length; i++) {
                if(!data[i].is_completed || data[i].is_completed == false) {
                    if(!state.currentQuestionID) {
                        state.currentQuestionID = data[i].id
                    }
                }
            }
            if(!state.currentQuestionID) {
                alert('Все вопросы пройдены!')
                router.push('/learning')
            }
            state.questions = data
        },

        setCurrentQuestionID(state, data) {
            state.currentQuestionID = data
        },

        setCurrentQuestion(state, data) {
            console.log(data)
            let array = data.game_ingredient_items
            if(array) {
                let m = array.length, t, i;

                // Пока есть элементы для перемешивания
                while (m) {
                    // Взять оставшийся элемент
                    i = Math.floor(Math.random() * m--);
    
                    // И поменять его местами с текущим элементом
                    t = array[m];
                    array[m] = array[i];
                    array[i] = t;
                }
                state.currentQuestion = data
                state.currentQuestion.game_ingredient_items = array
            }
        }
    },

    actions: {
        async fetchQuestion({ commit }) {
            try {
                const response = await axios.get(api + "/game-products/", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                const data = response.data
                commit('setQuestions', data)
            } catch(err) { // Если есть ошибка
                console.log(err)
            }
        },

        async fetchCurrentQuestion({ commit }, id) {
            try {
                const response = await axios.get(api + '/game-products/' + id + '/', {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setCurrentQuestion', response.data)
            } catch(err) { // Если есть ошибка
                console.log(err)
            }
        },

        async completeQuestion({ commit }, id) {
            try {
                const response = await axios.post(api + '/game-progress/', 
                {
                    game_product: id,
                    is_completed: true
                },
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setCurrentQuestion', response.data)
            } catch(err) { // Если есть ошибка
                console.log(err)
            }
        },
    }
}