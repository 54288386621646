<template>
<div class="window window-tasks window_full">
    <div class="window-header">
        <img src="@/assets/img/icons/icon-tasks.svg" alt="Иконка" class="window-header__icon" />
        <router-link to="/tasks" class="window-header__title"
            >Ближайшие задачи <span>( {{ tasks.length }} )</span></router-link
        >
    </div>

    <div
        class="window-item window-item_tasks"
        v-for="task in tasks"
        :key="task.id"
        :class="
            (task.description ? 'window-item_tasks_with-description' : '',
            tasks.length === 1 ? 'only_one' : '')
        "
    >
        <div class="window-item-header">
            <input
                v-model="task.is_completed"
                :id="'task_' + task.id"
                type="checkbox"
                class="checkbox checkbox_complete"
            />
            <label :for="'task_' + task.id" @click="changeTaskStatus(task.id, task.is_completed)">{{
                task.name
            }}</label>
            <div class="window-item-right">
                <span v-if="task.is_priority" class="window-item__status window-item__status_priority">
                    <div class="circle"></div>
                    важно
                </span>
                <span
                    class="window-item__status"
                    :class="task.is_expired ? ' window-item__status_expired' : ''"
                >
                    {{ new Date(task.date_to).toLocaleDateString() }}
                </span>
            </div>
        </div>  
        <div class="window-item-body" v-if="task.description">
            {{ task.description }}
        </div>
    </div>
    <div v-if="tasks.length === 0" class="window-item window-item_empty">
        <p>У вас пока нет задач</p>
    </div>
</div>
</template>
<script>
export default {
    props: {
        tasks: {
            type: Object,
            default: null
        }
    }
}
</script>