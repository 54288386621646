import axios from 'axios'
import router from '@/router';

export const segmentationModule = {
    namespaced: true,

    state: () => ({
        reportLoading: true,
        filterset: {},
        filtersetLoading: true,
        filterSpecs: {},
        data: {},
        excelData: {},
    }),

    mutations: {
        setFilterset(state, data) {
            state.filterset = data
            console.log(data);
            this.filterSpecs = {
                "date_range": [],
                "registration_date_range": [],
                "last_order_date_range": [],
                "shop": [],
                "delivery": [
                    "pickup",
                    "delivery"
                ],
                "week_day": [
                    {
                        "value": "weekdays",
                        "label": "Будние",
                        "checked": true
                    },
                    {
                        "value": "weekends",
                        "label": "Выходные",
                        "checked": true
                    }
                ],
                "revenue_from": null,
                "revenue_to": null,
                "average_check_from": null,
                "average_check_to": null,
                "orders_from": null,
                "orders_to": null,
                "interval_from": null,
                "interval_to": null
            }

            if(Object.keys(router.currentRoute.value.query).length == 0) {
                for(let filter in data) {
                    if(data[filter].multi_choices) {
                        state.filterSpecs[filter] = []
                        for(let i = 0; i < data[filter].choices.length; i++) {
                            if(data[filter].choices[i].checked) {
                                state.filterSpecs[filter].push(data[filter].choices[i].value)
                            }
                        }
                    }
    
                    if(data[filter].type === 'date') {
                        state.filterSpecs[filter] = []
                        
                        for(let i = 0; i < data[filter].choices.length; i++) {
                            if(data[filter].choices[i].value) {
                                state.filterSpecs[filter].push(data[filter].choices[i].value)
                            }
                        }
                    }
    
                    if(data[filter].type === 'number') {
                        for(let i = 0; i < data[filter].choices.length; i++) {
                            state.filterSpecs[data[filter].choices[i].spec] = data[filter].choices[i].value
                        }
                    }
    
                    if(filter === 'week_day') {
                        state.filterSpecs[filter] = data[filter].choices
                    }
                }
            } else {
                const queries = router.currentRoute.value.query

                state.filterSpecs['date_range'] = []

                queries.average_check_from ? state.filterSpecs['average_check_from'] = queries.average_check_from  : state.filterSpecs['average_check_from'] = null
                queries.average_check_to ? state.filterSpecs['average_check_to'] = queries.average_check_to  : state.filterSpecs['average_check_to'] = null

                queries.date_from ? state.filterSpecs['date_range'].push(queries.date_from) : state.filterSpecs['date_range'].push('')
                queries.date_to ? state.filterSpecs['date_range'].push(queries.date_to) : state.filterSpecs['date_range'].push('')

                queries.interval_from ? state.filterSpecs['interval_from'] = queries.interval_from : state.filterSpecs['interval_from'] = null
                queries.interval_to ? state.filterSpecs['interval_to'] = queries.interval_to : state.filterSpecs['interval_to'] = null

                queries.last_order_date_from ? state.filterSpecs['last_order_date_from'] = queries.last_order_date_from : state.filterSpecs['last_order_date_from'] = null
                queries.last_order_date_to ? state.filterSpecs['last_order_date_to'] = queries.last_order_date_to : state.filterSpecs['last_order_date_to'] = null

                queries.orders_from ? state.filterSpecs['orders_from'] = queries.orders_from : state.filterSpecs['orders_from'] = null
                queries.orders_to ? state.filterSpecs['orders_to'] = queries.orders_to : state.filterSpecs['orders_to'] = null

                queries.registration_date_from ? state.filterSpecs['registration_date_from'] = queries.registration_date_from : state.filterSpecs['registration_date_from'] = null
                queries.registration_date_to ? state.filterSpecs['registration_date_to'] = queries.registration_date_to : state.filterSpecs['registration_date_to'] = null

                queries.revenue_from ? state.filterSpecs['revenue_from'] = queries.revenue_from : state.filterSpecs['revenue_from'] = null
                queries.revenue_to ? state.filterSpecs['revenue_to'] = queries.revenue_to : state.filterSpecs['revenue_to'] = null

                if(queries.shop) {
                    if(Array.isArray(queries.shop)) {
                        state.filterSpecs['shop'] = Number(queries.shop)
                    } else {
                        state.filterSpecs['shop'] = [Number(queries.shop)]
                    }
                }

                if(queries.delivery) {
                    if(Array.isArray(queries.delivery)) {
                        state.filterSpecs['delivery'] = queries.delivery
                    } else {
                        state.filterSpecs['delivery'] = [queries.delivery]
                    }
                }

                state.filterSpecs['week_day'] = [
                    {
                        "value": "weekdays",
                        "label": "Будние",
                        "checked": queries.weekdays ? true : false
                    },
                    {
                        "value": "weekends",
                        "label": "Выходные",
                        "checked": queries.weekends ? true : false
                    }
                ]

                console.log(state.filterSpecs);
            }
            
            state.filtersetLoading = false            
        },

        setData(state, data) {
            state.data = data
            state.reportLoading = false
        },

        setExcelData(state, data) {
            state.excelData = data
        },
    },

    actions: {
        async getSegmentationFilterset({ commit, state }) {
            state.filtersetLoading = true
            try {
                const response = await axios.get("https://admin.tcode.online/api/reports/marketing-segmentation/filterset", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                // console.log(response.data);
                commit('setFilterset', response.data)
            } catch (err) { // Если есть ошибка
                console.log(err)
            }
        },

        async getSegmentationData({ commit, state }) {
            state.reportLoading = true
            if(router.currentRoute.value.fullPath.split('?')[1]) {
                try {
                    const response = await axios.get("https://admin.tcode.online/api/reports/marketing-segmentation/data?" + router.currentRoute.value.fullPath.split('?')[1], {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    commit('setData', response.data.data)
                    commit('setExcelData', response.data)
                } catch (err) { // Если есть ошибка
                    alert(err)
                }
            } else {
                try {
                    const response = await axios.get("https://admin.tcode.online/api/reports/marketing-segmentation/data", {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    commit('setData', response.data.data)
                    commit('setExcelData', response.data)

                } catch (err) { // Если есть ошибка
                    alert(err)
                }
            }
        },

        
        async getExcelFile({ state }) {
            try {
                const response = await axios.post("https://admin.tcode.online/api/reports/export_to_excel/",
                state.excelData,
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    },
                    responseType: 'blob'
                })

                const url = URL.createObjectURL(response.data)
                const a = document.createElement('a')
                a.href = url
                a.download = 'segmentation.xls'
                a.style.display = 'none'
                document.body.appendChild(a)
                a.click()
                a.remove()
                URL.revokeObjectURL(url)
                
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },
    }
}