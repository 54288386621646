import axios from 'axios'
import router from '@/router';

export const reportPreparationModule = {
    namespaced: true,

    state:() => ({
        reportData: {},
        reportLoading: true,
        filterset: {},
        filtersetLoading: true,
        filterSpecs: {},
        excelData: {},
    }),

    mutations: {
        setReportData(state, data) {
            state.reportData = data
        },

        setFilterset(state, data) {
            if(Object.keys(router.currentRoute.value.query).length == 0) {
                for(let filter in data) {
                    if(filter !== 'title') {
                        if(filter === 'input_number') {
                            state.filterSpecs['increase'] = 0
                        } else {
                            for(let i = 0; i < data[filter].choices.length; i++) {
                                if(filter === 'date_range') {
                                    state.filterSpecs['date'] = data[filter].choices[i].value
                                } else if (filter === 'compare_date_multi') {
                                    state.filterSpecs['compare_date'] = data[filter].choices[i]
                                } else {
                                    if(data[filter].choices[i].checked) {
                                        state.filterSpecs[filter] = data[filter].choices[i].value
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                const queries = router.currentRoute.value.query
                queries.date ? state.filterSpecs['date'] = queries.date : state.filterSpecs['date'] = ''
                queries.compare_date ? state.filterSpecs['compare_date'] = queries.compare_date : state.filterSpecs['compare_date'] = []
                queries.shop ? state.filterSpecs['shop'] = Number(queries.shop) : state.filterSpecs['shop'] = ''
                queries.mode ? state.filterSpecs['mode'] = queries.mode : state.filterSpecs['mode'] = ''
                queries.increase ? state.filterSpecs['increase'] = queries.increase : state.filterSpecs['increase'] = ''
            }
            
            state.filterset = data
            state.filtersetLoading = false
        },

        setExcelData(state, data) {
            state.excelData = data
        }
    },

    actions: {
        async getReportData({ commit, state }) {
            state.reportLoading = true
            // Проверяет, есть ли параметры которые можно передать в запросе
            if(router.currentRoute.value.fullPath.split('?')[1]) {
                try {
                    const response = await axios.get("https://admin.tcode.online/api/reports/preparation/data?" + router.currentRoute.value.fullPath.split('?')[1], {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    commit('setReportData', response.data.data)
                    commit('setExcelData', response.data)
                    state.reportLoading = false
                } catch(err) { // Если есть ошибка
                    alert(err)
                }
            // Если нет, делать запрос без параметров
            } else {
                try {
                    const response = await axios.get("https://admin.tcode.online/api/reports/preparation/data", {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    console.log(response.data);
                    commit('setReportData', response.data.data)
                    commit('setExcelData', response.data)
                    state.reportLoading = false
                } catch(err) { // Если есть ошибка
                    alert(err)
                }
            }
        },


        async getReportFilterset({ commit, state }) {
            state.filtersetLoading = true
            try {
                const response = await axios.get("https://admin.tcode.online/api/reports/preparation/filterset", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setFilterset', response.data)
            } catch(err) { // Если есть ошибка
                console.log(err);
            }
        },

        async getExcelFile({ state }) {
            try {
                const response = await axios.post("https://admin.tcode.online/api/reports/export_to_excel/",
                state.excelData,
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    },
                    responseType: 'blob'
                })
                const now = new Date()
                const url = URL.createObjectURL(response.data)
                const a = document.createElement('a')
                a.href = url
                a.download = `${state.excelData.title}_${now.toLocaleDateString()}.xls`
                a.style.display = 'none'
                document.body.appendChild(a)
                a.click()
                a.remove()
                URL.revokeObjectURL(url)
                
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },
    }
}