import axios from 'axios'
import api from '@/api'
import router from '@/router'

export const mediaModule = {
    namespaced: true,

    state:() => ({
        albums: null,
        media: null,
        mediaCount: 0,
    }),

    mutations: {
        setAlbums(state, data) {
            state.albums = data
            for(let i = 0; i < data.length; i++) {
                let date = data[i].date.split('-')
                state.albums[i].date = date[2] + '.' + date[1] + '.' + date[0]
            }
        },

        setMedia(state, data) {
            state.media = data
            state.mediaCount = data.items.length
        },
    },

    actions: {
        getAlbums({ commit }) {
            axios
            .get(api + "/media", {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setAlbums', response.data)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        getMedia({ commit }) {
            let id = router.currentRoute.value.params.id
            axios
            .get(api + "/media/" + id, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setMedia', response.data)
            })
            .catch((error) => {
                console.log(error.response);
            });
        }
    }
}