import axios from 'axios'
import api from '@/api'

export const profileModule = {
    namespaced: true,

    state:() => ({
        userdata: {}
    }),

    mutations: {
        setProfileData(state, data) {
            state.userdata = data
        },
    },

    actions: {
        getUserData({ commit }) {
            axios
            .get(api + "/profile/", {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                console.log(response.data);
                commit('setProfileData', response.data)
            })
            .catch((error) => {
                console.log(error.response);
            });
        }
    }
}