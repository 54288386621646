import axios from 'axios'
import router from '@/router';

export const dashboardModule = {
    namespaced: true,

    state: () => ({
        reportLoading: true,
        filterset: {},
        filtersetLoading: true,
        filterSpecs: {},
        data: null,
        chartOptions: {},
        chartData: {},
        chartLoading: true,
        chartRevenue: [],
        chartLoyalty: [],
        chartLabels: []
    }),

    mutations: {
        setData(state, data) {
            state.data = data
        },

        setFilterset(state, data) {
            state.filterSpecs['date_range'] = []

            if(Object.keys(router.currentRoute.value.query).length == 0) {
                for(let filter in data) {
                    if(filter !== 'title') {
                        for(let i = 0; i < data[filter].choices.length; i++) {
                            if(filter === 'date_range') {
                                state.filterSpecs[filter].push(data[filter].choices[i].value)
                            } else {
                                if(data[filter].choices[i].checked) {
                                    state.filterSpecs[filter] = data[filter].choices[i].value
                                }
                            }
                        }
                    }
                }
            } else {
                const queries = router.currentRoute.value.query

                state.filterSpecs['date_range'] = []

                queries.date_from ? state.filterSpecs['date_range'].push(queries.date_from) : state.filterSpecs['date_range'].push('')
                queries.date_to ? state.filterSpecs['date_range'].push(queries.date_to) : state.filterSpecs['date_range'].push('')

                queries.project ? state.filterSpecs['project'] = queries.project : state.filterSpecs['project'] = ''
            }
            
            state.filterset = data
            state.filtersetLoading = false
        },

        setChartRevenue(state, data) {
            state.chartRevenue = data
        },

        setChartLoyalty(state, data) {
            state.chartLoyalty = data
        },

        setChartLabels(state, data) {
            state.chartLabels = data
        }
    },

    getters: {
        getChartOptions(state) {
            state.chartOptions = {
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }

            return state.chartOptions
        },

        getChartData(state) {
            state.chartData = {
                labels: state.chartLabels,
                datasets: [
                    {
                        "label": "выручка",
                        "backgroundColor": "#7992F9",
                        "borderColor": "#7992F9",
                        "data": state.chartRevenue,
                        "tension": 0.3
                    },
                    {
                        "label": "лояльность",
                        "backgroundColor": "#F2B20E",
                        "borderColor": "#F2B20E",
                        "data": state.chartLoyalty,
                        "tension": 0.3,
                        "spanGaps": true
                    },
                ]
            }

            return state.chartData
        }
    },

    actions: {
        async getDashboardFilterset({ commit }) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/reports/marketing-dashboard/filterset", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setFilterset', response.data)
            } catch (err) { // Если есть ошибка
                alert(err)
            }
        },

        async getDashboardData({ commit, state }) {
            state.reportLoading = true

            // Проверяет, есть ли параметры которые можно передать в запросе
            if (router.currentRoute.value.fullPath.split('?')[1]) {
                try {
                    const response = await axios.get("https://admin.tcode.online/api/reports/marketing-dashboard/data?" + router.currentRoute.value.fullPath.split('?')[1], {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    commit('setData', response.data.data)
                    state.reportLoading = false
                } catch (err) { // Если есть ошибка
                    alert(err)
                }
                // Если нет, делать запрос без параметров
            } else {
                try {
                    const response = await axios.get("https://admin.tcode.online/api/reports/marketing-dashboard/data", {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    commit('setData', response.data.data)
                    state.reportLoading = false
                } catch (err) { // Если есть ошибка
                    alert(err)
                }
            }
        },

        changeChart({ commit, state }, data) {
            state.chartLoading = true

            let labels = []
            let revenue = []
            let loyalty = []
            for(let label in state.data.dynamic.by_shops[data]) {
                labels.push(label)
                revenue.push(state.data.dynamic.by_shops[data][label].revenue)
                loyalty.push(state.data.dynamic.by_shops[data][label].loyalty)
            }
        
            commit('setChartLabels', labels)
            commit('setChartRevenue', revenue)
            commit('setChartLoyalty', loyalty)

            state.chartLoading = false
        }
    }
}