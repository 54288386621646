import axios from 'axios'
import api from '@/api'

export const learningModule = {
    namespaced: true,

    state:() => ({
        loading: true,
        chapters: null,
        polls: null,
        test: null,
        poll_progress_id: null,
        points: null,
        total_points: null,
    }),

    mutations: {
        setChapters(state, data) {
            state.chapters = data
        },

        setPolls(state, data) {
            state.polls = data
        },

        setTest(state, data) {
            state.test = data
        },

        setPollProgressId(state, data) {
            state.poll_progress_id = data
        }
    },

    actions: {
        getChapters({ commit }) {
            axios
            .get(api + "/chapters/", {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setChapters', response.data)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        getPolls({ commit }) {
            axios
            .get(api + "/polls/", {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setPolls', response.data)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        getTest({ state, commit }, id) {
            state.points = null

            axios
            .get(api + "/polls/" + id, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setTest', response.data)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        endTest({ state }, data) {
            const answers = {}

            for(let i = 0; i < data.questions.length; i++) {
                const elem = data.questions[i].answer_choices.find(x => x.checked)
                if(elem)
                    answers[data.questions[i].id] = elem.id
            }

            if(Object.keys(answers).length != 0) {
                axios
                .post(api + "/poll-progress/", {
                    poll_id: data.id,
                    answers: answers

                },
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    if(response.data.is_completed) {
                        state.total_points = response.data.questions_count
                        state.points = response.data.correct_answers_count
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                });
            }
        }
    }
}