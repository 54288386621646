import axios from 'axios'

export const invoiceModule = {
    namespaced: true,

    state:() => ({
        isLoading: false,
        invoiceData: null,
    }),

    mutations: {
        setInvoiceData(state, data) {            
            for(let i = 0; i < data.items.length; i++) {
                data.items[i].is_edited = false
            }

            state.invoiceData = data
        }
    },

    actions: {
        getInvoiceData({ commit, state }, order_id) {
            state.isLoading = true

            axios
            .get(`https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/order-edit/${order_id}/`, {})
            .then((response) => {
                console.log(response.data.order);
                commit('setInvoiceData', response.data.order)
                state.isLoading = false
            })
            .catch((err) => {
                console.log(err);
            })

        },

        changeOrderProductCount({}, data) {
            axios
            .get("https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/order-edit/"+ data.order_id +"/?item_edit=" + data.item_id +"&item_quantity=" + data.item_count, {})
            .then((response) => {
                console.log(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
        },

        changeOrderData({ dispatch }, data) {
            let date = data.date.split('.')
            let format_date = date[2] + '-' + date[1] + '-' + date[0]
            
            axios
            .get("https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/order-edit/"+ data.order_id +"/?incoming_invoice_number=" + data.number + "&incoming_invoice_date=" + format_date, {})
            .then((response) => {
                if(response.data.success) {
                    document.location.reload()
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }
}