<template>
    <div class="sidebar" id="sidebar">
        <div class="sidebar-links">
            <div v-for="item in sidebarItems.filter((x) => x.visible)" :key="item.name">
                <router-link
                    v-if="!item.dropdown"
                    class="sidebar__item"
                    :to="item.path"
                    :class="$route.name === item.name ? 'active' : ''"
                >
                    <div class="sidebar__item-rec"></div>
                    <img :src="require('@/assets/img/sidebar/' + item.icon)" alt="" />
                    {{ item.title }}
                </router-link>

                <div v-else class="sidebar__item_dropdown">
                    <div
                        class="sidebar__item sidebar__item_dropdown-header"
                        :class="item.active ? 'active' : ''"
                        @click="dropdownController(item.name)"
                    >
                        <div class="sidebar__item-rec"></div>
                        <img :src="require('@/assets/img/sidebar/' + item.icon)" alt="" />
                        {{ item.title }}
                        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1 3L7 9L13 3"
                                stroke="#2E343B"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                    <div class="sidebar__item_dropdown-body" :class="item.active ? 'active' : ''">
                        <router-link
                            v-for="link in item.items.filter((x) => x.visible)"
                            :key="link.title"
                            :to="link.path"
                            :class="$route.name === link.name ? 'active' : ''"
                        >
                            {{ link.title }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="sidebar sidebar_mobile" id="mobile_sidebar" :class="mobileSidebar ? 'active' : ''">
        <div class="sidebar-links">
            <div v-for="item in sidebarItems.filter((x) => x.visible)" :key="item.name">
                <router-link
                    v-if="!item.dropdown"
                    class="sidebar__item"
                    :to="item.path"
                    :class="$route.name === item.name ? 'active' : ''"
                >
                    <div class="sidebar__item-rec"></div>
                    <img :src="require('@/assets/img/sidebar/' + item.icon)" alt="" />
                    {{ item.title }}
                </router-link>

                <div v-else class="sidebar__item_dropdown">
                    <div
                        class="sidebar__item sidebar__item_dropdown-header"
                        :class="item.active ? 'active' : ''"
                        @click="dropdownController(item.name)"
                    >
                        <div class="sidebar__item-rec"></div>
                        <img :src="require('@/assets/img/sidebar/' + item.icon)" alt="" />
                        {{ item.title }}
                        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1 3L7 9L13 3"
                                stroke="#2E343B"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                    <div class="sidebar__item_dropdown-body" :class="item.active ? 'active' : ''">
                        <router-link
                            v-for="link in item.items"
                            :key="link.title"
                            :to="link.path"
                            :class="$route.name === link.name ? 'active' : ''"
                        >
                            {{ link.title }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
    data() {
        return {
            routePath: this.$route.path,
        };
    },

    props: {
        routeName: {
            type: String,
            default: "home",
        },
    },

    computed: {
        ...mapState({
            sidebarItems: (state) => state.sidebarItems,
            user_permissions: (state) => state.user_permissions,
            mobileSidebar: (state) => state.mobileSidebar,
        }),
    },

    methods: {
        ...mapActions({
            dropdownController: "dropdownController",
        }),
    },

    // mounted() {
    //     this.$store.dispatch("mobileSidebarController");
    // },

    watch: {
        $route(newValue) {
            const group = newValue.meta.group;
            
            if(this.mobileSidebar) {
                this.$store.dispatch("mobileSidebarController");
            }

            const elem = this.sidebarItems.find((x) => x.name === group);
            if (elem) {
                elem.active = true;
            }
        },
    },
};
</script>
