import axios from 'axios'
import router from '@/router';

export const rfmModule = {
    namespaced: true,

    state: () => ({
        global_loading: true,
        filterset: null,
        filtersetLoading: true,
        filterSpecs: {},
        main_data_loading: true,
        main_data: {},
        high_data_loading: true,
        high_data: {},
        medium_data_loading: true,
        medium_data: {},
        low_data_loading: true,
        low_data: {}
    }),

    mutations: {
        setFilterset(state, data) {
            if(Object.keys(router.currentRoute.value.query).length == 0) {
                for(let filter in data) {
                    state.filterSpecs[filter] = []
                    if(filter !== 'title') {
                        for(let i = 0; i < data[filter].choices.length; i++) {
                            if(filter === 'date_range') {
                                state.filterSpecs[filter].push(data[filter].choices[i].value)
                            } else {
                                if(data[filter].choices[i].checked) {
                                    state.filterSpecs[filter] = data[filter].choices[i].value
                                }
                            }
                        }
                    }
                }
            } else {
                const queries = router.currentRoute.value.query

                state.filterSpecs['date_range'] = []

                queries.date_from ? state.filterSpecs['date_range'].push(queries.date_from) : state.filterSpecs['date_range'].push('')
                queries.date_to ? state.filterSpecs['date_range'].push(queries.date_to) : state.filterSpecs['date_range'].push('')

                queries.project ? state.filterSpecs['project'] = queries.project : state.filterSpecs['project'] = ''
            }
            
            state.filterset = data

            state.filtersetLoading = false
        },

        setMainData(state, data) {
            state.main_data = data
            state.main_data_loading = false
        },

        setHighData(state, data) {
            state.high_data = data
            state.high_data_loading = false
        },

        setMediumData(state, data) {
            state.medium_data = data
            state.medium_data_loading = false
        },

        setLowData(state, data) {
            state.low_data = data
            state.low_data_loading = false
            state.global_loading = false
        },
    },

    actions: {
        async getRFMFilterset({ commit, state }) {
            state.filtersetLoading = true
            try {
                const response = await axios.get("https://admin.tcode.online/api/reports/marketing-rfm/filterset", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setFilterset', response.data)
            } catch (err) { // Если есть ошибка
                alert(err)
            }
        },

        getMainData({ commit, state, dispatch }) {
            state.main_data_loading = true
            state.global_loading = true

            if(router.currentRoute.value.fullPath.split('?')[1]) {
                axios
                .get("https://admin.tcode.online/api/reports/marketing-rfm/data?segment=all&" + router.currentRoute.value.fullPath.split('?')[1], {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    console.log(response.data);
                    commit('setMainData', response.data.data)
                    dispatch('getHighData')
                })
                .catch((error) => {
                    console.log(error.response);
                });
            } else {
                axios
                .get("https://admin.tcode.online/api/reports/marketing-rfm/data?segment=all", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    console.log(response.data);
                    commit('setMainData', response.data.data)
                    dispatch('getHighData')
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        },

        getHighData({ commit, state, dispatch }) {
            state.high_data_loading = true
            if(router.currentRoute.value.fullPath.split('?')[1]) {
                axios
                .get("https://admin.tcode.online/api/reports/marketing-rfm/data?segment=high&" + router.currentRoute.value.fullPath.split('?')[1], {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    console.log(response.data);
                    commit('setHighData', response.data.data)
                    dispatch('getMediumData')
                })
                .catch((error) => {
                    console.log(error.response);
                });
            } else {
                axios
                .get("https://admin.tcode.online/api/reports/marketing-rfm/data?segment=high", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    console.log(response.data);
                    commit('setHighData', response.data.data)
                    dispatch('getMediumData')
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        },

        getMediumData({ commit, state, dispatch }) {
            state.medium_data_loading = true
            if(router.currentRoute.value.fullPath.split('?')[1]) {
                axios
                .get("https://admin.tcode.online/api/reports/marketing-rfm/data?segment=medium&" + router.currentRoute.value.fullPath.split('?')[1], {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    console.log(response.data);
                    commit('setMediumData', response.data.data)
                    dispatch('getLowData')
                })
                .catch((error) => {
                    console.log(error.response);
                });
            } else {
                axios
                .get("https://admin.tcode.online/api/reports/marketing-rfm/data?segment=medium", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    console.log(response.data);
                    commit('setMediumData', response.data.data)
                    dispatch('getLowData')
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        },

        getLowData({ commit, state }) {
            state.low_data_loading = true
            if(router.currentRoute.value.fullPath.split('?')[1]) {
                axios
                .get("https://admin.tcode.online/api/reports/marketing-rfm/data?segment=low&" + router.currentRoute.value.fullPath.split('?')[1], {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    console.log(response.data);
                    commit('setLowData', response.data.data)
                })
                .catch((error) => {
                    console.log(error.response);
                });
            } else {
                axios
                .get("https://admin.tcode.online/api/reports/marketing-rfm/data?segment=low", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    console.log(response.data);
                    commit('setLowData', response.data.data)
                })
                .catch((error) => {
                    console.log(error.response);
                });
            }
        },
    }
}