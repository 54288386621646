import axios from 'axios'
import router from '@/router';

export const productsModule = {
    namespaced: true,

    state:() => ({
        isLoading: true,
        points_of_sale: null,
        filterset: {
            'is_active': [
                {
                    id: 0,
                    label: 'Активные',
                    value: true
                },
                {
                    id: 1,
                    label: 'Не активные',
                    value: false,
                },
            ],
            'is_available_for_bonuses': [
                {
                    id: 0,
                    label: 'Доступные',
                    value: true
                },
                {
                    id: 1,
                    label: 'Не доступные',
                    value: false,
                },
            ],
            'available': [
                {
                    id: 0,
                    label: 'Самовывоз',
                    value: 'by_pickup'
                },
                {
                    id: 1,
                    label: 'Доставка',
                    value: 'by_delivery',
                },
            ],
            'is_popular': [
                {
                    id: 0,
                    label: 'Популярные',
                    value: true
                },
                {
                    id: 1,
                    label: 'Не популярные',
                    value: false
                },
            ],
        },
        filterSpecs: {
            'all': true,
            'shop': '',
            'is_active': '',
            'is_available_for_bonuses': '',
            'category': [],
            'search': '',
            'available': '',
            'is_popular': ''
        },
        products: null,
        current_product: null,
    }),

    mutations: {
        setPointsOfSale(state, data) {
			state.points_of_sale = data
		},

        setFilterSpecs(state) {
            const queries = router.currentRoute.value.query

            queries.shop ? state.filterSpecs['shop'] = queries.shop : state.filterSpecs['shop'] = state.filterSpecs['shop'] = state.points_of_sale[0].value
            queries.is_active ? state.filterSpecs['is_active'] = queries.is_active : state.filterSpecs['is_active'] = ''
            queries.is_available_for_bonuses ? state.filterSpecs['is_available_for_bonuses'] = queries.is_available_for_bonuses : state.filterSpecs['is_available_for_bonuses'] = ''
            queries.search ? state.filterSpecs['search'] = queries.search : state.filterSpecs['search'] = ''
            queries.available ? state.filterSpecs['available'] = queries.shoavailablep : state.filterSpecs['available'] = ''
            queries.is_popular ? state.filterSpecs['is_popular'] = queries.is_popular : state.filterSpecs['is_popular'] = ''
        },

        setProducts(state, data) {
            state.products = data
        },

        setCurrentProduct(state, data) {
            state.current_product = data
        }
    },

    actions: {
        getPointOfSale({ commit }) {
            axios
            .get("https://admin.tcode.online/api/projects/tcode/shops/", {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                for(let i = 0; i < response.data.length; i++) {
                    response.data[i].label = response.data[i].name
                    response.data[i].value = response.data[i].code
                }

                commit('setPointsOfSale', response.data)
                commit('setFilterSpecs')
            })
            .catch((error) => {
                console.log(error);
            });
        },
        
        fetchProducts({ state, commit }) {
            state.isLoading = true
            const shop = router.currentRoute.value.query.shop

            axios
            .get(`https://admin.tcode.online/api/projects/tubatay/shops/${shop}/products/?` + router.currentRoute.value.fullPath.split('?')[1], {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setProducts', response.data.results)
                state.isLoading = false
            })
            .catch((error) => {
                console.log(error);
            });
        },

        fetchCurrentProduct({ state, commit }) {
            state.isLoading = true
            const route = router.currentRoute.value

            axios
            .get(`https://admin.tcode.online/api/projects/tubatay/shops/${route.params.shop}/products/${route.params.id}?all=true`, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setCurrentProduct', response.data)
                state.isLoading = false
            })
            .catch((error) => {
                console.log(error);
            });
        },

        editProduct({}, data) {
            const route = router.currentRoute.value

            axios
            .patch(`https://admin.tcode.online/api/projects/tubatay/shops/${route.query.shop}/products/${data.id}/?all=true`,
                data.formData,
                {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },

        resetFilterSpecs({ state }) {
            state.filterSpecs = {
                'all': true,
                'shop': state.points_of_sale[0].value,
                'is_active': '',
                'is_available_for_bonuses': '',
                'category': [],
                'search': '',
                'available': '',
                'is_popular': ''
            }
        }
    }
}