import axios from 'axios'

export const shiftPlaningModule = {
    namespaced: true,

    state:() => ({
        isLoading: true,
        shiftRoles: null,
        sections: null,
        shiftPlanings: null,
        defaultSection: null,
    }),

    mutations: {
        setShiftRoles(state, data) {
            state.shiftRoles = data
        },

        setSections(state, data) {
            if(data.length !== 0) {
                state.sections = data
            } else {
                state.sections = null
            }
        },

        setShiftPlanings(state, data) {
            if(data.length !== 0) {
                state.shiftPlanings = data
                state.defaultSection = data[0].id
            } else {
                state.shiftPlanings = null
                state.defaultSection = null
            }
        }
    },

    actions: {
        fetchSections({ commit }, shop) {
            axios
            .get(`https://admin.tcode.online/api/projects/tubatay/${shop}/sections`, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setSections', response.data)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        fetchShiftPlanings({ state, commit }, shop) {
            state.isLoading = true
            axios
            .get(`https://admin.tcode.online/api/projects/tubatay/${shop}/shift-plannings`, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setShiftPlanings', response.data)
                state.isLoading = false
            })
            .catch((error) => {
                console.log(error);
            });
        },

        createNewSection({ dispatch }, data) {
            axios
            .post(`https://admin.tcode.online/api/projects/tubatay/${data.shop}/shift-plannings/`,
            {
                shop: data.shop,
                revenue: data.revenue,
                efficiency: data.efficiency
            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('fetchShiftPlanings', data.shop_code)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        deleteSection({ dispatch }, data) {
            axios
            .delete(`https://admin.tcode.online/api/projects/tubatay/${data.shop}/shift-plannings/${data.id}/`,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('fetchShiftPlanings', data.shop)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        addRow({ dispatch }, data) {
            const roles = []

            for(let i = 0; i < data.roles.length; i++) {
                roles.push(data.roles[i].id)
            }

            axios
            .post(`https://admin.tcode.online/api/projects/tubatay/${data.shop}/shift-planning-roles/`,
            {
                description: data.description,
                section: data.section,
                roles: roles,
                hours: data.hours,
                shift_planning: data.shift_planning
            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('fetchShiftPlanings', data.shop)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        editRow({ dispatch }, data) {
            const roles = []
            for(let i = 0; i < data.roles.length; i++) {
                roles.push(data.roles[i].id)
            }
            axios
            .patch(`https://admin.tcode.online/api/projects/tubatay/${data.shop}/shift-planning-roles/${data.id}/`,
            {
                description: data.description,
                section: data.section,
                roles: roles,
                hours: data.hours,
                shift_planning: data.shift_planning
            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('fetchShiftPlanings', data.shop)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        deleteRow({ dispatch }, data) {
            axios
            .delete(`https://admin.tcode.online/api/projects/tubatay/${data.shop}/shift-planning-roles/${data.id}/`,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                dispatch('fetchShiftPlanings', data.shop)
            })
            .catch((error) => {
                console.log(error.response);
            });
        }
    }
}