import router from '@/router';
import axios from 'axios'
import api from '@/api'
import store from '@/store'

export const loginModule = {
    namespaced: true,

    state:() => ({
        verInputCode: false,
        token: null,
        error: false,
    }),

    mutations: {
        setVerCode(state, bool) {
            state.verInputCode = bool
        },

        setToken(state, token) {
            state.token = token
        },

        setError(state, bool) {
            state.error = bool
        }
    },

    actions: {
        setNumberPhone({ commit }, phone) {
            if (phone !== null) {
                axios
                .post(api + "/auth/phone/", {
                    phone: phone,
                })
                .then((response) => {
                    if(response.data.success) {
                        commit('setVerCode', true)
                    }
                })
                .catch((error) => {
                    commit('setError', true)
                });
            }
        },

        validateCode({ commit, dispatch }, data) {
            const validPhone = data.phone.replace(/\s+/g, '').slice(1)
            axios
            .post(api + "/auth/verification/", {
                phone: validPhone,
                code: data.code,
            })
            .then((response) => {
                commit('setToken', response.data.token)
                
                dispatch('userAuth')
            })
            .catch((error) => {
                commit('setVerCode', true)
            });
        },

        async userAuth({ state, dispatch }) {
            try {
                const response = await axios.get(api + "/profile/", {
                    headers: {
                        Authorization: 'Token ' + state.token
                    }
                })

                localStorage.setItem('token', state.token);
                store.dispatch('getProfileData')
                router.replace({ path: '/' })
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },
    }
}