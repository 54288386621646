import axios from 'axios'
import router from '@/router';

export const reportPlanModule = {
    namespaced: true,

    state:() => ({
        reportData: {},
        reportLoading: true,
        reportFilterset: {},
        filtersetLoading: true,
        filterSpecs: {},
        defaultDate: [],
        excelData: {},
    }),

    mutations: {
        setReportData(state, data) {
            state.reportData = data
        },

        setReportFilterset(state, data) {
            if(Object.keys(router.currentRoute.value.query).length == 0) {
                for(let filter in data) {
                    if(filter !== 'title') {
                        if(data[filter].multi_choices || data[filter].choices.length > 1) {
                            state.filterSpecs[filter] = []
                        } else {
                            state.filterSpecs[filter] = ''
                        }
    
                        if(data[filter].type === 'date') {
                            for(let i = 0; i < data[filter].choices.length; i++) {
                                if(data[filter].choices.length > 1) {
                                    const formatDate = data[filter].choices[i].value.split("-").reverse().join(".");
                                    state.filterSpecs[filter].push(formatDate)
                                } else {
                                    const formatDate = data[filter].choices[i].value.split("-").reverse().join(".");
                                    state.filterSpecs[filter] = formatDate
                                }
                            }
                        }
                    }
                }
            } else {
                const queries = router.currentRoute.value.query
                state.filterSpecs['date_range'] = [
                    queries.date_from.split("-").reverse().join("."),
                    queries.date_to.split("-").reverse().join(".")
                ]

                if(Array.isArray(queries.shop)) {
                    state.filterSpecs['shop'] = queries.shop
                } else {
                    state.filterSpecs['shop'] = [queries.shop]
                }
            }
            state.reportFilterset = data
        },

        setInitFilters(state, data) {
            state.filterSpecs['date_range'] = [data.date_from, data.date_to]
        },

        setExcelData(state, data) {
            state.excelData = data
        }
    },

    actions: {
        async getReportData({ commit, state }) {
            state.reportLoading = true
            // Проверяет, есть ли параметры которые можно передать в запросе
            if(router.currentRoute.value.fullPath.split('?')[1]) {
                try {
                    const response = await axios.get("https://admin.tcode.online/api/reports/plan/data?" + router.currentRoute.value.fullPath.split('?')[1], {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    commit('setReportData', response.data.data)
                    commit('setExcelData', response.data)
                } catch(err) { // Если есть ошибка
                    alert(err)
                }
            // Если нет, делать запрос без параметров
            } else {
                try {
                    const response = await axios.get("https://admin.tcode.online/api/reports/plan/data", {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    commit('setReportData', response.data.data)
                    commit('setExcelData', response.data)
                } catch(err) { // Если есть ошибка
                    alert(err)
                }
            }

            state.reportLoading = false
        },

        async getReportFilterset({ commit, state }) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/reports/plan/filterset", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setReportFilterset', response.data)
                state.filtersetLoading = false
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },

        resetFilters({ state }) {
            for(let spec in state.filterSpecs) {
                if(Array.isArray(state.filterSpecs[spec])) {
                    state.filterSpecs[spec] = []
                } else {
                    state.filterSpecs[spec] = ''
                }
            }
        },

        async getExcelFile({ state }) {
            try {
                const response = await axios.post("https://admin.tcode.online/api/reports/export_to_excel/",
                state.excelData,
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    },
                    responseType: 'blob'
                })

                const url = URL.createObjectURL(response.data)
                const a = document.createElement('a')
                a.href = url
                a.download = 'report_plan.xls'
                a.style.display = 'none'
                document.body.appendChild(a)
                a.click()
                a.remove()
                URL.revokeObjectURL(url)
                
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },
    }
}