import axios from "axios";
import router from "@/router";

export const reportStudyModule = {
    namespaced: true,

    state: () => ({
        reportData: {},
        reportLoading: true,
        filterset: {},
        filtersetLoading: true,
        filterSpecs: {},
        excelData: {},
        certificateData: null
    }),

    mutations: {
        setReportData(state, data) {
            console.log(data);
            state.reportData = data;
            state.reportLoading = false;
        },

        setReportFilterset(state, data) {
            state.filterSpecs["shop"] = [];

            if(Object.keys(router.currentRoute.value.query).length == 0) {
                for(let filter in data) {
                    if(filter !== 'title') {
                        for(let i = 0; i < data[filter].choices.length; i++) {  
                            if(data[filter].multi_choices) {
                                if(data[filter].choices[i].checked) {
                                    state.filterSpecs[filter].push(data[filter].choices[i].value)
                                }
                            } else {
                                if(data[filter].choices[i].checked) {
                                    state.filterSpecs[filter] = data[filter].choices[i].value
                                }
                            }
                        }
                    }
                }
            } else {
                const queries = router.currentRoute.value.query

                for(let filter in data) {
                    if(data[filter].multi_choices) {
                        state.filterSpecs[filter] = []
                    } else {
                        state.filterSpecs[filter] = ''
                    }
                }

                for(let query in queries) {
                    if(query) {
                        if(typeof state.filterSpecs[query] !== 'undefined') {
                            if(typeof state.filterSpecs[query] !== 'string') {
                                if(typeof queries[query] !== 'string') {
                                    state.filterSpecs[query] = queries[query]
                                } else {
                                    state.filterSpecs[query].push(queries[query])
                                }
                            } else {
                                state.filterSpecs[query] = queries[query]
                            }
                        }
                    }
                }
            }
            
            state.filterset = data
            state.filtersetLoading = false
        },

        setExcelData(state, data) {
            state.excelData = data;
        },
    },

    actions: {
        async getReportData({ commit, state }) {
            state.reportLoading = true;
            // Проверяет, есть ли параметры которые можно передать в запросе
            if (router.currentRoute.value.fullPath.split("?")[1]) {
                try {
                    const response = await axios.get(
                        "https://admin.tcode.online/api/reports/study/data?" +
                            router.currentRoute.value.fullPath.split("?")[1],
                        {
                            headers: {
                                Authorization: "Token " + localStorage.getItem("token"),
                            },
                        }
                    );
                    commit("setReportData", response.data.data);
                    commit("setExcelData", response.data);
                } catch (err) {
                    // Если есть ошибка
                    alert(err);
                }
                // Если нет, делать запрос без параметров
            } else {
                try {
                    const response = await axios.get("https://admin.tcode.online/api/reports/study/data", {
                        headers: {
                            Authorization: "Token " + localStorage.getItem("token"),
                        },
                    });
                    commit("setReportData", response.data.data);
                    commit("setExcelData", response.data);
                } catch (err) {
                    // Если есть ошибка
                    alert(err);
                }
            }
        },

        async getReportFilterset({ commit }) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/reports/study/filterset", {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                });
                commit("setReportFilterset", response.data);
            } catch (err) {
                // Если есть ошибка
                alert(err);
            }
        },

        async getExcelFile({ state }) {
            try {
                const response = await axios.post(
                    "https://admin.tcode.online/api/reports/export_to_excel/",
                    state.excelData,
                    {
                        headers: {
                            Authorization: "Token " + localStorage.getItem("token"),
                        },
                        responseType: "blob",
                    }
                );
                const now = new Date();
                const url = URL.createObjectURL(response.data);
                const a = document.createElement("a");
                a.href = url;
                a.download = `${state.excelData.title}_${now.toLocaleDateString()}.xls`;
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(url);
            } catch (err) {
                // Если есть ошибка
                alert(err);
            }
        },

        async setCertificate({ dispatch }, data) {
            console.log(data);
            if(typeof data.created_at !== 'string') {
                data.created_at = data.created_at.toLocaleDateString().split('.').reverse().join('-')
            }

            try {
                const response = await axios.post("https://admin.tcode.online/api/projects/tcode/certifications/",
                    data,
                {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                });

                dispatch('getReportData')
            } catch (err) {
                // Если есть ошибка
                alert(err);
            }
        },

        async getCertificate({ state }, id) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/projects/tcode/certifications/" + id,
                {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                });
                console.log(response.data);
                state.certificateData = response.data
            } catch (err) {
                // Если есть ошибка
                alert(err);
            }
        },

        async editCertificate({ dispatch }, data) {
            try {
                const response = await axios.patch("https://admin.tcode.online/api/projects/tcode/certifications/" + data.id + '/',
                {
                    comment: data.comment,
                    create_at: data.create_at
                },
                {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                });

                dispatch('getReportData')
            } catch (err) {
                // Если есть ошибка
                alert(err);
            }
        },

        async deleteCertificate({ dispatch }, id) {
            try {
                const response = await axios.delete("https://admin.tcode.online/api/projects/tcode/certifications/" + id,
                {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                });
                dispatch('getReportData')
            } catch (err) {
                // Если есть ошибка
                alert(err);
            }
        },
    },
};
