import axios from 'axios'
import api from '@/api'

export const calendarModule = {
    namespaced: true,

    state:() => ({
        selectedShop: null,
        date_from: null,
        date_to: null,
        daysStatuses: null
    }),

    mutations: {
        setShop(state, data) {
            state.selectedShop = data
        },

        setDays(state, data) {
            state.date_from = data[0],
            state.date_to = data[1]
        },

        setDaysStatuses(state, data) {
            state.daysStatuses = data
        }
    },

    actions: {
        getCalendarDays({ commit, state, rootState }) {
            rootState.isLoading = true
            let shopCode = null

            for(let i = 0; i < rootState.points_of_sale.length; i++) {
                if(state.selectedShop === rootState.points_of_sale[i].value) {
                    shopCode = rootState.points_of_sale[i].code
                }
            }
            axios
            .get(api + "/" + shopCode + "/schedule-days/?date_from=" + state.date_from + '&date_to=' + state.date_to,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setDaysStatuses', response.data)
                rootState.isLoading = false
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },

        changeDays({ commit }, data) {
            commit('setDays', data)
        },

        changeShop({ commit }, data) {
            commit('setShop', data)
        }
    }
}