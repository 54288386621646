import axios from 'axios'
import router from '@/router';

export const reportDDSModule = {
    namespaced: true,

    state:() => ({
        reportData: {},
        reportLoading: true,
        reportFilterset: {},
        filtersetLoading: true,
        filterSpecs: {},
        defaultDate: null,
        defaultMonth: null,
        excelData: {},
        prevData: null,
        points_of_sale: null,
        DDSArticles: null
    }),

    mutations: {
        setReportData(state, data) {
            state.reportData = data
        },

        setReportFilterset(state, data) {
            if(Object.keys(router.currentRoute.value.query).length == 0) {
                for(let filter in data) {
                    if(filter !== 'title') {
                        if(data[filter].multi_choices || data[filter].choices.length > 1) {
                            state.filterSpecs[filter] = []
                        } else {
                            state.filterSpecs[filter] = ''
                        }

                        if(data[filter].spec === 'shop') {
                            for(let i = 0; i < data[filter].choices.length; i++) {
                                if(data[filter].choices[i].checked) {
                                    state.filterSpecs[filter] = data[filter].choices[i].value
                                }
                            }
                        }
                    }
                }
            } else {
                const queries = router.currentRoute.value.query
                queries.shop ? state.filterSpecs['shop'] = Number(queries.shop) : state.filterSpecs['shop'] = ''
                queries.month ? state.defaultMonth = queries.month : state.defaultMonth = null
                queries.date_to ? state.defaultDate = queries.date_to : state.defaultDate = null
            }

            state.reportFilterset = data
        },

        setExcelData(state, data) {
            state.excelData = data
        },

        setPrevData(state, data) {
            state.prevData = data
        },

        setPointsOfSale(state, data) {
			state.points_of_sale = data
			for(let i = 0; i < data.length; i++) {
				state.points_of_sale[i].label = data[i].name
				state.points_of_sale[i].value = data[i].id
			}
		},

        setDDSArticles(state, data) {
            for(let i = 0; i < data.length; i++) {
                data[i].label = data[i].name + ' - ' + data[i].summary_name
                data[i].value = data[i].id
            }

            state.DDSArticles = data
        }
    },

    actions: {
        async getReportData({ commit, state }) {
            state.reportLoading = true
            // Проверяет, есть ли параметры которые можно передать в запросе
            if(router.currentRoute.value.fullPath.split('?')[1]) {
                try {
                    const response = await axios.get("https://admin.tcode.online/api/reports/dds/data?" + router.currentRoute.value.fullPath.split('?')[1], {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    commit('setReportData', response.data.data)
                    commit('setExcelData', response.data)
                } catch(err) { // Если есть ошибка
                    alert(err)
                }
            // Если нет, делать запрос без параметров
            } else {
                try {
                    const response = await axios.get("https://admin.tcode.online/api/reports/dds/data", {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    commit('setReportData', response.data.data)
                    commit('setExcelData', response.data)
                } catch(err) { // Если есть ошибка
                    alert(err)
                }
            }
            state.reportLoading = false
        },

        async getReportFilterset({ commit, state }) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/reports/dds/filterset", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setReportFilterset', response.data)
                state.filtersetLoading = false
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },

        resetFilters({ state }) {
            state.filterSpecs['shop'] = state.reportFilterset['shop'].choices[0].value
        },

        async getExcelFile({ state }) {
            try {
                const response = await axios.post("https://admin.tcode.online/api/reports/export_to_excel/",
                state.excelData,
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    },
                    responseType: 'blob'
                })
                const url = URL.createObjectURL(response.data)
                const a = document.createElement('a')
                a.href = url
                a.download = 'report_dds.xls'
                a.style.display = 'none'
                document.body.appendChild(a)
                a.click()
                a.remove()
                URL.revokeObjectURL(url)
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },

        async changeValue({ dispatch }, data) {
            if(data.data.predict_type_calc) {
                try {
                    const response = await axios.patch("https://admin.tcode.online/api/reports/dds/edit/items/" + data.id + '/', {
                        predict_type_calc: data.data.predict_type_calc,
                        predict_value: data.data.predict_value,
                        predict_is_manual: true
                    },
                    {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    dispatch('getReportData')
                } catch(err) { // Если есть ошибка
                    alert(err)
                }
            } else if (data.data.plan_type_calc) {
                try {
                    const response = await axios.patch("https://admin.tcode.online/api/reports/dds/edit/items/" + data.id + '/', {
                        plan_type_calc: data.data.plan_type_calc,
                        plan_value: data.data.plan_value,
                        plan_is_manual: true
                    },
                    {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    dispatch('getReportData')
                } catch(err) { // Если есть ошибка
                    alert(err)
                }
            } else if (data.data.fact_type_calc) {
                try {
                    const response = await axios.patch("https://admin.tcode.online/api/reports/dds/edit/items/" + data.id + '/', {
                        fact_type_calc: data.data.fact_type_calc,
                        fact_value: data.data.fact_value,
                        fact_is_manual: true
                    },
                    {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    dispatch('getReportData')
                } catch(err) { // Если есть ошибка
                    alert(err)
                }
            } else if (Object.keys(data.data)[0] == 'description') {
                try {
                    const response = await axios.patch("https://admin.tcode.online/api/reports/dds/edit/items/" + data.id + '/', {
                        description: data.data.description
                    },
                    {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    dispatch('getReportData')
                } catch(err) { // Если есть ошибка
                    alert(err)
                }
            }
        },

        async getPrevData({ commit, dispatch }, data) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/reports/dds/edit/copy/?shop="+ data.shop +"&month="+ data.month + '&force=true', {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setPrevData', response.data)
                dispatch('getReportData')
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },

        async resetValue({ commit, dispatch }, data) {
            if(data.data.predict_type_calc) {
                try {
                    const response = await axios.patch("https://admin.tcode.online/api/reports/dds/edit/items/" + data.id + '/', {
                        predict_type_calc: 'amount',
                        predict_value: 0,
                        predict_is_manual: false
                    },
                    {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    dispatch('getReportData')
                } catch(err) { // Если есть ошибка
                    alert(err)
                }
            } else if (data.data.plan_type_calc) {
                try {
                    const response = await axios.patch("https://admin.tcode.online/api/reports/dds/edit/items/" + data.id + '/', {
                        plan_type_calc: 'amount',
                        plan_value: 0,
                        plan_is_manual: false
                    },
                    {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    dispatch('getReportData')
                } catch(err) { // Если есть ошибка
                    alert(err)
                }
            } else if (data.data.fact_type_calc) {
                try {
                    const response = await axios.patch("https://admin.tcode.online/api/reports/dds/edit/items/" + data.id + '/', {
                        fact_type_calc: 'amount',
                        fact_value: 0,
                        fact_is_manual: false
                    },
                    {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                    dispatch('getReportData')
                } catch(err) { // Если есть ошибка
                    alert(err)
                }
            }
        },

        async getPointOfSale({ commit, state }) {
			try {
				const response = await axios.get("https://admin.tcode.online/api/projects/tcode/shops/", {
					headers: {
						Authorization: 'Token ' + localStorage.getItem('token')
					}
				})
				commit('setPointsOfSale', response.data)
			} catch (err) {
				alert(err)
			}
		},

        async getDDSArticles({ commit }) {
            try {
                const response = await axios.get("https://admin.tcode.online/api/projects/tcode/articles-dds/", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setDDSArticles', response.data)
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },

        async setBills({}, data) {
            try {
                const response = await axios.post("https://admin.tcode.online/api/projects/tcode/bills/",
                data,
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        }
    }
}